import React from "react";
import { useState } from "react";
import { FiArrowUpCircle } from "react-icons/fi";

const GoTopButton = () => {
  const [goTopButton, setGoTopButton] = useState(false);

  // function to show Go To Top Button after scrolling down more than 50px
  const showGoTopButton = () => {
    if (window.scrollY >= 50) {
      setGoTopButton(true);
    } else {
      setGoTopButton(false);
    }
  };
  window.addEventListener("scroll", showGoTopButton);

  // function to scroll all the way back to the top of the page with a smooth behavior
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div
      onClick={scrollToTop}
      className={
        goTopButton
          ? "bg-black bg-opacity-70 w-[75px] sm:w-[100px] rounded-l-full p-1 fixed bottom-24 right-0 cursor-pointer scale-75 sm:scale-100 duration-500"
          : "bg-black bg-opacity-70 w-[75px] sm:w-[100px] rounded-l-full p-1 fixed bottom-24 right-[-75px] sm:right-[-100px] cursor-pointer scale-75 sm:scale-100 duration-500"
      }
    >
      <FiArrowUpCircle
        size={50}
        className=" text-pink-500 bg-pink-900 rounded-[50%] animate-pulse"
      />
    </div>
  );
};

export default GoTopButton;
