import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import HashLoader from "react-spinners/HashLoader";
import useFirestore from "../customHooks/useFirestore";

const ImageGrid = (props) => {
  const { photoCategory, setSelectedImage } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [allPhotos, setAllPhotos] = useState([]);

  const { docs } = useFirestore("All Photos");
  // console.log(docs);

  useEffect(() => {
    setAllPhotos(docs);
    setTimeout(() => {
      setIsLoading(false);
    }, 3500);
  }, [docs]);

  // Filtering docs with Category prop
  const categoryFilteredDocs = allPhotos.filter((photo) => {
    return photo.category === photoCategory;
  });
  // console.log(categoryFilteredDocs);

  // Framer Motion Variant Props
  const imageGridContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const imageGridItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center scale-50 lg:scale-75 xl:scale-100">
          {/* Loading Spinner Animation */}
          <HashLoader color="#ec489a" size={100} speedMultiplier={2} />
        </div>
      ) : (
        <motion.div
          className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-1"
          layout
          variants={imageGridContainer}
          initial="hidden"
          animate="show"
        >
          {/* When no photoCategory is specified to show all photos if any photos exist */}
          {!isLoading &&
            photoCategory === "" &&
            allPhotos.map((doc) => (
              <motion.div
                key={doc.id}
                onClick={() => setSelectedImage(doc.URL)}
                className="overflow-hidden h-0 py-[50%] px-0 relative"
                variants={imageGridItem}
              >
                <motion.img
                  src={doc.URL}
                  alt={doc.name}
                  className="absolute top-0 left-0 min-w-full min-h-full object-cover cursor-zoom-in opacity-70 hover:opacity-100 hover:scale-125 duration-200"
                ></motion.img>
              </motion.div>
            ))}
          {/* When photoCategory is specified to show that category photos if any photos exist */}
          {!isLoading &&
            photoCategory &&
            categoryFilteredDocs.map((categoryFilteredDoc) => (
              <motion.div
                key={categoryFilteredDoc.id}
                onClick={() => setSelectedImage(categoryFilteredDoc.URL)}
                className="overflow-hidden h-0 py-[50%] px-0 relative"
                variants={imageGridItem}
              >
                <motion.img
                  src={categoryFilteredDoc.URL}
                  alt={categoryFilteredDoc.name}
                  className="absolute top-0 left-0 min-w-full min-h-full object-cover cursor-zoom-in opacity-70 hover:opacity-100 hover:scale-125 duration-200"
                ></motion.img>
              </motion.div>
            ))}
        </motion.div>
      )}
    </>
  );
};

export default ImageGrid;
