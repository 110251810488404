/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { storage, firestore } from "../firebase/firebase-config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

const useStorage = (category, photoFile) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    // References
    const storageRef = ref(storage, `All Photos/${category}/${photoFile.name}`);
    const uploadTask = uploadBytesResumable(storageRef, photoFile);
    // Three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var percentageProgress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(percentageProgress);
      },
      (error) => {
        // Handle unsuccessful uploads
        setError(error);
      },
      async () => {
        // Uploading Image to Firebase storage + getting Download URL to hide Progress Bar when done
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          // Adding Image Download URL to Image Collection in Firebase firestore (db)
          addDoc(collection(firestore, "All Photos"), {
            category: category,
            name: photoFile.name,
            URL: downloadURL,
            timestamp: serverTimestamp(),
          });
        });
      }
    );
  }, [photoFile]);

  return { progress, error, url };
};

export default useStorage;
