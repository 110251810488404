import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/autoplay";
import ARPhotoLogoSquareWhite from "../assets/AR-Photo-White-Square-NF.png";
import ARPhotoLogoRectangleWhite from "../assets/AR-Photo-White-Rectangle-NF.png";
import AircraftImage from "../assets/Aircraft.webp";
import AstroImage from "../assets/Astro.webp";
import MotorsportImage from "../assets/Motorsport.webp";
import PyrotechnicImage from "../assets/Pyrotechnic.webp";
import StormImage from "../assets/Storm.webp";
import Footer from "../components/Footer";
import { motion } from "framer-motion";

const Landing = () => {
  // Framer Motion Variant Props
  const photoCategoriesContainer = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delayChildren: 1.3,
        staggerChildren: 0.4,
      },
    },
    exiting: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        staggerDirection: -1,
      },
    },
  };

  const photoCategoryItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
    exiting: { opacity: 0 },
  };

  return (
    <motion.div
      className="min-h-screen flex flex-col justify-between"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { delay: 1.3 } }}
      transition={{ duration: 0.3, delay: 0.5 }}
    >
      {/* Swiper Effect Background Images */}
      <div className="LandingContainer absolute z-0">
        <Swiper
          modules={[Navigation, EffectFade, Autoplay]}
          effect={"fade"}
          speed={2000}
          slidesPerView={1}
          loop
          autoplay={{ delay: 3000 }}
          className="SwiperContainer"
        >
          <SwiperSlide className="SwiperSlideImage">
            <img src={AircraftImage} alt="Aircraft" />
          </SwiperSlide>
          <SwiperSlide className="SwiperSlideImage">
            <img src={AstroImage} alt="Astro" />
          </SwiperSlide>
          <SwiperSlide className="SwiperSlideImage">
            <img src={MotorsportImage} alt="Motorsport" />
          </SwiperSlide>
          <SwiperSlide className="SwiperSlideImage">
            <img src={PyrotechnicImage} alt="Pyrotechnic" />
          </SwiperSlide>
          <SwiperSlide className="SwiperSlideImage">
            <img src={StormImage} alt="Storm" />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* Background Images Overlay for darkening and blurring */}
      <div className="overlayFilter absolute z-[5px]"></div>
      {/* Logo Icon */}
      <div className="relative flex justify-center">
        <img
          src={ARPhotoLogoRectangleWhite}
          alt="Logo"
          className="hidden sm:flex sm:h-24 h-32 mt-5"
        />
        <img
          src={ARPhotoLogoSquareWhite}
          alt="Logo"
          className="flex w-28 h-28 sm:hidden mt-5"
        />
      </div>
      {/* Photo Categories */}
      <div className="relative px-5 sm:py-5">
        <motion.ul
          className="w-full text-white flex flex-col gap-x-10 gap-y-2 md:gap-y-5 flex-wrap items-center justify-center text-3xl md:text-4xl xl:text-5xl font-black uppercase duration-200"
          variants={photoCategoriesContainer}
          initial="hidden"
          animate="show"
          exit="exiting"
        >
          <motion.li
            className="py-1 relative cursor-default"
            variants={photoCategoryItem}
          >
            <Link to="/Aircraft">
              <h1 className="underline-red-landing">Aircraft</h1>
            </Link>
          </motion.li>
          <motion.li
            className="py-1 relative cursor-default"
            variants={photoCategoryItem}
          >
            <Link to="/Astro">
              <h1 className="underline-red-landing">Astro</h1>
            </Link>
          </motion.li>
          <motion.li
            className="py-1 relative cursor-default"
            variants={photoCategoryItem}
          >
            <Link to="/Motorsport">
              <h1 className="underline-red-landing">Motorsport</h1>
            </Link>
          </motion.li>
          <motion.li
            className="py-1 relative cursor-default"
            variants={photoCategoryItem}
          >
            <Link to="/Pyrotechnic">
              <h1 className="underline-red-landing">Pyrotechnic</h1>
            </Link>
          </motion.li>
          <motion.li
            className="py-1 relative cursor-default"
            variants={photoCategoryItem}
          >
            <Link to="/Storm">
              <h1 className="underline-red-landing">Storm</h1>
            </Link>
          </motion.li>
          <motion.li
            className="py-1 relative cursor-default"
            variants={photoCategoryItem}
          >
            <Link to="/All">
              <h1 className="underline-red-landing">All</h1>
            </Link>
          </motion.li>
        </motion.ul>
      </div>
      {/* Footer */}
      <Footer />
    </motion.div>
  );
};

export default Landing;
