/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Landing from "../pages/Landing";
import Aircraft from "../pages/Aircraft";
import Astro from "../pages/Astro";
import Motorsport from "../pages/Motorsport";
import Pyrotechnic from "../pages/Pyrotechnic";
import Storm from "../pages/Storm";
import All from "../pages/All";
import Login from "../pages/Login";
import Register from "../pages/Register";
// Firebase
import { auth } from "../firebase/firebase-config";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import Contact from "../pages/Contact";

function AnimatedRoutes() {
  const [user, setUser] = useState("");
  const [userUID, setUserUID] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");

  const navigate = useNavigate();

  // To clear input fields Register and Login
  const clearInputs = () => {
    setEmail("");
    setPassword("");
    setPasswordConfirm("");
  };

  // To clear error labels Register and Login
  const clearErrors = () => {
    setEmailError("");
    setPasswordError("");
    setPasswordConfirmError("");
  };

  // Register User
  const handleRegister = async (e) => {
    // e.preventDefault();
    clearErrors();

    if (password === "") {
      setPasswordError("Password required");
    }

    if (passwordConfirm === "") {
      setPasswordConfirmError("Password required");
    }

    if (password !== passwordConfirm) {
      return (
        setPasswordError("Passwords do not match"),
        setPasswordConfirmError("Passwords do not match")
      );
    }

    try {
      const createUser = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(createUser);
      // console.log(user);
      clearInputs();
      navigate("/All", { replace: true });
    } catch (error) {
      switch (error.code) {
        case "auth/email-already-in-use":
        case "auth/invalid-email":
          setEmailError(error.code);
          break;
        case "auth/weak-password":
          setPasswordError(error.code);
          setPasswordConfirmError(error.code);
          break;
      }
    }
  };

  // Login User
  const handleLogin = async (e) => {
    // e.preventDefault();
    clearErrors();

    if (password === "") {
      setPasswordError("Password required");
    }

    try {
      const loggedUser = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      setUser(loggedUser);
      clearInputs();
      navigate("/All", { replace: true });
    } catch (error) {
      switch (error.code) {
        case "auth/invalid-email":
        case "auth/user-disabled":
        case "auth/user-not-found":
          setEmailError(error.code);
          break;
        case "auth/wrong-password":
          setPasswordError(error.code);
          break;
      }
    }
  };

  // Logout User
  const handleLogout = async () => {
    await signOut(auth);
    setUser("");
    setUserUID("");
    setUserEmail("");
  };

  // Listener to keep track of current User Logged In and get/set UUID
  const authListener = () => {
    onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        clearInputs();
        clearErrors();
        setUser(currentUser);
        // console.log(currentUser);
        setUserUID(currentUser.uid);
        // console.log(currentUser.email);
        setUserEmail(currentUser.email);
      } else {
        setUser("");
      }
    });
  };

  // Useeffect to run authListener on render
  useEffect(() => {
    authListener();
  }, []);

  const location = useLocation();
  // console.log(location.pathname);

  // All Pages
  return (
    <div className="min-h-screen flex flex-col">
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Landing />} />
          <Route
            path="/Aircraft"
            element={<Aircraft user={user} handleLogout={handleLogout} />}
          />
          <Route
            path="/Astro"
            element={<Astro user={user} handleLogout={handleLogout} />}
          />
          <Route
            path="/Motorsport"
            element={<Motorsport user={user} handleLogout={handleLogout} />}
          />
          <Route
            path="/Pyrotechnic"
            element={<Pyrotechnic user={user} handleLogout={handleLogout} />}
          />
          <Route
            path="/Storm"
            element={<Storm user={user} handleLogout={handleLogout} />}
          />
          <Route
            path="/All"
            element={
              <All
                user={user}
                userUID={userUID}
                userEmail={userEmail}
                handleLogout={handleLogout}
              />
            }
          />
          <Route
            path="/Register"
            element={
              <Register
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                passwordConfirm={passwordConfirm}
                setPasswordConfirm={setPasswordConfirm}
                handleRegister={handleRegister}
                emailError={emailError}
                passwordError={passwordError}
                passwordConfirmError={passwordConfirmError}
                clearErrors={clearErrors}
                clearInputs={clearInputs}
              />
            }
          />
          <Route
            path="/Login"
            element={
              <Login
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                handleLogin={handleLogin}
                emailError={emailError}
                passwordError={passwordError}
                clearErrors={clearErrors}
                clearInputs={clearInputs}
              />
            }
          />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default AnimatedRoutes;
