import React, { useState } from "react";
import ImageGrid from "../components/ImageGrid";
import Modal from "../components/Modal";
import PhotoUpload from "../components/PhotoUpload";
import { motion, AnimatePresence } from "framer-motion";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import GoTopButton from "../components/GoTopButton";

const All = (props) => {
  const { userUID, userEmail, user, handleLogout } = props;
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <motion.div
      className=" w-full h-[auto] flex-1 flex flex-col justify-between"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* NavBar  */}
      <NavBar user={user} handleLogout={handleLogout} />
      {/* Upload Section for Admin User */}
      <div
        className={
          userUID === process.env.REACT_APP_FIREBASE_ALLOWED_UID_PROD
            ? "w-full my-5 px-5 duration-200"
            : "hidden"
        }
      >
        <PhotoUpload userEmail={userEmail} />
      </div>
      {/* All Photos to go here */}
      <div className="w-full h-full mb-5 px-5">
        {/* All Photos - No photo category specified */}
        <ImageGrid photoCategory="" setSelectedImage={setSelectedImage} />
        {/* Modal view */}
        <AnimatePresence>
          {selectedImage && (
            <div>
              <Modal
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
              />
            </div>
          )}
        </AnimatePresence>
      </div>
      {/* Footer */}
      <Footer />
      {/* Go Top Button */}
      <GoTopButton />
    </motion.div>
  );
};

export default All;
