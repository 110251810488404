/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import { FcHome } from "react-icons/fc";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useState } from "react";
import { FcCheckmark } from "react-icons/fc";
import { BiError } from "react-icons/bi";
import PulseLoader from "react-spinners/PulseLoader";

const Contact = () => {
  const initialValues = { name: "", email: "", phone: "", message: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isNotSuccess, setIsNotSuccess] = useState(false);
  // const [successNotification, setSuccessNotification] = useState(false);
  // const [errorNotification, setErrorNotification] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  //input fields validations
  const validate = (values) => {
    const errors = {};
    const nameRegex = /^[a-zA-Z ]*$/;
    const phoneRegex = /^[0-9]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    // Name
    if (!values.name || values.name.length <= 3) {
      errors.name = "Full Name Required";
    } else if (!nameRegex.test(values.name)) {
      errors.name = "Alphabetical Values Only";
    }
    // Email
    if (!values.email) {
      errors.email = "Email Required";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "Email Format Invalid";
    }
    // Phone
    if (!values.phone) {
      errors.phone = "Phone Required";
    } else if (!phoneRegex.test(values.phone)) {
      errors.phone = "Numeric Values Only";
    }
    // Message
    if (!values.message || /^\s*$/g.test(values.message)) {
      errors.message = "Message Required";
    }
    return errors;
  };

  useEffect(() => {
    // console.log(formErrors);
    // if no errors present and isSubmit is true
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // turn Send Button into Processing Button
      setIsProcessing(true);
      // console.log(formValues);
      // make fetch request to send Contact Form values to my email
      fetch("https://formsubmit.co/ajax/419e55a8aa53a7c625bd2f0894bc8dff", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Name: formValues.name,
          Email: formValues.email,
          Phone: formValues.phone,
          Message: formValues.message,
        }),
      })
        .then((response) => response.json())
        // if fetch response and data successful turn Processing Button to Success Button and clear input fields
        .then((data) =>
          setTimeout(() => {
            setIsProcessing(false);
            setIsSuccess(true);
            setFormValues({
              ...formValues,
              name: "",
              email: "",
              phone: "",
              message: "",
            });
          }, 3000)
        )
        // if fetch response and data error turn Processing Button to isNotSuccess Button and leave input fields filled
        .catch((error) => setIsNotSuccess(true));
      // finally set states to false again after 5 seconds so any notifications disappear
      setTimeout(function () {
        setIsSuccess(false);
        setIsNotSuccess(false);
        setIsProcessing(false);
        setIsSubmit(false);
      }, 7000);
    }
  }, [formErrors]);

  return (
    <motion.div
      className="w-screen min-h-screen px-5 py-10 text-gray-500 flex flex-row items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* Contact Form */}
      <form className="text-center w-96" onSubmit={handleSubmit}>
        <p className="text-2xl font-medium uppercase pb-5">Contact</p>
        {/* Container Top Accent */}
        <div className="h-3 bg-white rounded-t-xl animate-pulse"></div>
        {/* Contact Container */}
        <div className="flex flex-col bg-gray-900 p-5 text-left  rounded-b-xl">
          <p className="flex flex-col gap-2 items-center justify-center text-center uppercase pb-5">
            <em>
              Contact me for any
              <span className="text-pink-700 font-bold"> Photo</span> Purchase
              and / or any Editorial or Commercial Use
              <span className="text-pink-700 font-bold"> Requests</span>.
            </em>
          </p>
          {/* Name */}
          <label className="text-lg font-normal uppercase">Name</label>
          <input
            className="bg-gray-800 w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-2 focus:ring-white rounded-lg text-md text-gray-500"
            type="text"
            autoFocus
            name="name"
            value={formValues.name}
            onChange={handleChange}
          ></input>
          <p className="mt-1 text-red-600 text-sm font-medium uppercase tracking-wider">
            {formErrors.name}&nbsp;
          </p>
          {/* Email */}
          <label className="text-lg font-normal uppercase">Email</label>
          <input
            className="bg-gray-800 w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-2 focus:ring-white rounded-lg text-md text-gray-500"
            type="text"
            name="email"
            value={formValues.email}
            onChange={handleChange}
          ></input>
          <p className="mt-1 text-red-600 text-sm font-medium uppercase tracking-wider">
            {formErrors.email}&nbsp;
          </p>
          {/* Phone */}
          <label className="text-lg font-normal uppercase">Phone</label>
          <input
            className="bg-gray-800 w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-2 focus:ring-white rounded-lg text-md text-gray-500"
            type="tel"
            name="phone"
            value={formValues.phone}
            onChange={handleChange}
          ></input>
          <p className="my-1 text-red-600 text-sm font-medium uppercase tracking-wider">
            {formErrors.phone}&nbsp;
          </p>
          {/* Message*/}
          <label className="text-lg font-normal uppercase">Message</label>
          <textarea
            className="bg-gray-800 w-full resize-none overflow-auto px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-2 focus:ring-white rounded-lg text-md text-gray-500"
            type="text"
            rows="5"
            cols="50"
            name="message"
            value={formValues.message}
            onChange={handleChange}
          ></textarea>
          <p className="my-1 text-red-600 text-sm font-medium uppercase tracking-wider">
            {formErrors.message}&nbsp;
          </p>
          {/* Send Button */}
          <div className="mt-2 flex flex-col items-center gap-5">
            {/* Button states in Process, is Successful, is Not Successful, Normal Send Button */}
            {isProcessing ? (
              <button className="w-[105px] cursor-not-allowed pointer-events-none duration-200 py-2 px-6 rounded-lg text-xl font-bold uppercase">
                <PulseLoader
                  color="#ec489a"
                  margin={4}
                  size={10}
                  speedMultiplier={0.75}
                />
              </button>
            ) : isSuccess ? (
              <button className="w-[105px] h-[44px] flex items-center justify-center  cursor-not-allowed pointer-events-none duration-200 py-2 px-6 rounded-lg text-xl font-bold uppercase text-center">
                <FcCheckmark size={30} className=" duration-200 " />
              </button>
            ) : isNotSuccess ? (
              <button className="w-[105px] h-[44px] bg-red-600 text-white flex items-center justify-center  cursor-not-allowed pointer-events-none duration-200 py-2 px-6 rounded-lg text-xl font-bold uppercase text-center">
                <BiError size={30} className=" duration-200 " />
              </button>
            ) : (
              <button className="w-[105px] text-gray-800 bg-white hover:bg-gray-500 duration-200 py-2 px-6 rounded-lg text-xl font-bold uppercase pulseSendButton">
                Send
              </button>
            )}
            {/* Home Button */}
            <div className="flex flex-col mt-3 gap-2 items-center justify-center text-center">
              <Link to="/">
                <FcHome
                  size={35}
                  onClick={() => {
                    setFormValues({
                      ...formValues,
                      name: "",
                      email: "",
                      phone: "",
                      message: "",
                    });
                  }}
                  className="animate-bounce"
                />
              </Link>
            </div>
          </div>
        </div>
      </form>
    </motion.div>
  );
};

export default Contact;
