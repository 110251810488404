import React, { useState } from "react";
import { FcAddImage } from "react-icons/fc";
import ProgressBar from "./ProgressBar";

const PhotoUpload = (props) => {
  const { userEmail } = props;

  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [photoFile, setPhotoFile] = useState("");
  const [photoError, setPhotoError] = useState("");

  const [isDisabled, setIsDisabled] = useState(true);

  // Photo types allowed
  const photoTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];

  const handleClickPhotoUpload = () => {
    setCategoryError("");
    setPhotoError("");

    if (category === "") {
      setCategoryError("Category Required");
      setIsDisabled(true);
    } else {
      setCategoryError("");
      setIsDisabled(false);
      setPhotoFile("");
      setPhotoError("");
    }
  };

  const handleChangePhotoUpload = (e) => {
    var selectedPhoto = e.target.files[0];

    if (selectedPhoto && photoTypes.includes(selectedPhoto.type)) {
      setPhotoFile(selectedPhoto);
      setPhotoError("");
    } else if (selectedPhoto && !photoTypes.includes(selectedPhoto.type)) {
      setPhotoFile("");
      setPhotoError("Images Only");
    } else {
      setCategory("");
      setCategoryError("");
      setPhotoFile("");
      setPhotoError("");
    }
  };

  return (
    <form className="flex flex-col items-center bg-gray-900 p-3 rounded-3xl">
      <p className="py-2 text-white text-lg md:text-xl font-medium uppercase text-center flex flex-col">
        Logged User{" "}
        <span className="text-pink-700 text-sm md:text-lg font-bold tracking-wider">
          <em>{userEmail}</em>
        </span>
      </p>
      <p className="py-2 text-white text-lg md:text-xl font-medium uppercase text-center">
        Upload New Photo
      </p>
      <p className="py-2 text-gray-500 text-base md:text-lg font-medium uppercase text-center">
        <em>Select Category</em>
      </p>
      {/* Category Select List */}
      <div>
        <select
          className="bg-gray-700 text-gray-500 p-1 hover:outline-none focus:outline-none focus:ring-2 focus:ring-pink-500 rounded-lg text-base md:text-lg uppercase cursor-pointer"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option></option>
          <option>Aircraft</option>
          <option>Astro</option>
          <option>Motorsport</option>
          <option>Pyrotechnic</option>
          <option>Storm</option>
        </select>
      </div>
      {/* Category Select List Error */}
      <div className="w-full text-center text-xs sm:text-base">
        {categoryError && (
          <p className="mt-3 text-red-600 text-sm font-medium uppercase tracking-widest">
            {categoryError}
          </p>
        )}
      </div>
      <p className="pt-2 text-gray-500 text-base md:text-lg font-medium uppercase text-center">
        <em>Select Photo</em>
      </p>
      {/* Photo Select Dialog - disabled at first in case no category is selected */}
      <label
        onClick={handleClickPhotoUpload}
        className="cursor-pointer duration-200"
      >
        <FcAddImage size={70} />
        <input
          className="hidden"
          type="file"
          disabled={isDisabled}
          onChange={handleChangePhotoUpload}
        />
      </label>
      {/* Photo Error, Photo Name, Photo Upload Progress Bar */}
      <div className="w-full text-center text-xs sm:text-base">
        {photoError && (
          <p className="mt-2 text-red-600 text-sm font-medium uppercase tracking-widest">
            {photoError}
          </p>
        )}
        {photoFile && (
          <p className="pb-1 mt-2 text-red-600 text-sm font-medium uppercase">
            {photoFile.name}
          </p>
        )}
        {photoFile && (
          <ProgressBar
            category={category}
            setCategory={setCategory}
            photoFile={photoFile}
            setPhotoFile={setPhotoFile}
          />
        )}
      </div>
    </form>
  );
};

export default PhotoUpload;
