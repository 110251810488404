import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ARPhotoLogoSquareWhite from "../assets/AR-Photo-White-Square-NF.png";
import { FaBars, FaTimes } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const NavBar = (props) => {
  const { user, handleLogout } = props;

  const [hamburger, setHamburger] = useState(true);
  const [navbarBGColor, setNavbarBGColor] = useState(false);

  const handleHamburger = () => setHamburger(!hamburger);

  // function to add bg under Navbar on scrolling
  const changeNavBarBG = () => {
    if (window.scrollY >= 5) {
      setNavbarBGColor(true);
    } else {
      setNavbarBGColor(false);
    }
  };
  window.addEventListener("scroll", changeNavBarBG);

  // function to check window size to close Menu if window big enough
  const hideMobileMenuOnWidthChange = () => {
    if (!hamburger && window.innerWidth >= 1024) {
      setHamburger(!hamburger);
    }
  };
  window.addEventListener("resize", hideMobileMenuOnWidthChange);

  return (
    <>
      {/* NavBar Logo and Menu Items */}
      <div
        className={
          navbarBGColor
            ? "w-full h-[auto] top-0 left-0 sticky z-10 px-5 flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between bg-black bg-opacity-80 backdrop-blur-[5px] duration-200"
            : "w-full h-[auto] top-0 left-0 sticky z-10 px-5 flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between duration-200"
        }
      >
        {/* Logo Icon */}
        <div>
          <Link to="/">
            <img
              src={ARPhotoLogoSquareWhite}
              alt="Logo"
              className={
                navbarBGColor
                  ? "w-28 h-28 lg:w-48 lg:h-48 mt-5 mb-5 opacity-70 duration-200"
                  : "w-28 h-28 lg:w-48 lg:h-48 mt-5 mb-5 duration-200"
              }
            />
          </Link>
        </div>
        {/* Navbar Menu Links */}
        <ul
          className={
            navbarBGColor
              ? "h-[50px] mt-5 text-white hidden lg:flex flex-row gap-5 items-center text-2xl font-medium uppercase opacity-70 duration-200"
              : "h-[50px] mt-5 text-white hidden lg:flex flex-row gap-5 items-center text-2xl font-medium uppercase duration-200"
          }
        >
          <li className="relative">
            <CustomLink to="/Aircraft">
              <h1 className="underline-red">Aircraft</h1>
            </CustomLink>
          </li>
          <li className="relative">
            <CustomLink to="/Astro">
              <h1 className="underline-red ">Astro</h1>
            </CustomLink>
          </li>
          <li className="relative">
            <CustomLink to="/Motorsport">
              <h1 className="underline-red">Motorsport</h1>
            </CustomLink>
          </li>
          <li className="relative">
            <CustomLink to="/Pyrotechnic">
              <h1 className="underline-red">Pyrotechnic</h1>
            </CustomLink>
          </li>
          <li className="relative">
            <CustomLink to="/Storm">
              <h1 className="underline-red">Storm</h1>
            </CustomLink>
          </li>
          <li className="relative">
            <CustomLink to="/All">
              <h1 className="underline-red">All</h1>
            </CustomLink>
          </li>
          {/* Login / Logout Buttons */}
          {user ? (
            <li>
              <button
                className="w-[100px] h-[50px] text-gray-800 bg-pink-200 hover:bg-pink-600 duration-200 p-1 text-md rounded-lg uppercase font-bold"
                onClick={handleLogout}
              >
                Logout
              </button>
            </li>
          ) : (
            <li>
              <Link to="/Login">
                <button className="w-[100px] h-[50px] text-gray-500 hover:text-pink-600 duration-200 py-1 px-2 text-md rounded-lg uppercase font-bold">
                  Login
                </button>
              </Link>
            </li>
          )}
        </ul>
      </div>
      {/* Hamburger / Close Toggle Button */}
      <div
        onClick={handleHamburger}
        className="fixed top-0 right-0 z-20 mt-5 mr-5 lg:hidden cursor-pointer"
      >
        {hamburger ? (
          <FaBars
            size={30}
            className={
              navbarBGColor
                ? "text-white  opacity-70 duration-200"
                : "text-white duration-200"
            }
          />
        ) : (
          <FaTimes size={30} className="text-[#ff0000]" />
        )}
      </div>
      {/* Overlay for darkening and blurring below Menu - Mobile*/}
      <AnimatePresence>
        {!hamburger && (
          <motion.div
            className="fixed z-10 w-screen h-screen top-0 left-0 bg-black bg-opacity-[0.85] object-cover backdrop-blur-[5px]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          ></motion.div>
        )}
      </AnimatePresence>
      {/* Menu - Mobile */}
      <AnimatePresence>
        {!hamburger && (
          <motion.ul
            onClick={handleHamburger}
            className="fixed z-10 top-0 left-0 w-screen h-screen text-white flex flex-col items-center justify-center gap-5 text-3xl md:text-4xl font-medium uppercase"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <li className="relative" onClick={handleHamburger}>
              <CustomLink to="/Aircraft">
                <h1 className="underline-red">Aircraft</h1>
              </CustomLink>
            </li>
            <li className="relative" onClick={handleHamburger}>
              <CustomLink to="/Astro">
                <h1 className="underline-red ">Astro</h1>
              </CustomLink>
            </li>
            <li className="relative" onClick={handleHamburger}>
              <CustomLink to="/Motorsport">
                <h1 className="underline-red">Motorsport</h1>
              </CustomLink>
            </li>
            <li className="relative" onClick={handleHamburger}>
              <CustomLink to="/Pyrotechnic">
                <h1 className="underline-red">Pyrotechnic</h1>
              </CustomLink>
            </li>
            <li className="relative" onClick={handleHamburger}>
              <CustomLink to="/Storm">
                <h1 className="underline-red">Storm</h1>
              </CustomLink>
            </li>
            <li className="relative" onClick={handleHamburger}>
              <CustomLink to="/All">
                <h1 className="underline-red">All</h1>
              </CustomLink>
            </li>
            {/* Login / Logout Buttons */}
            {user ? (
              <li>
                <button
                  className="w-[auto] mt-2 text-gray-800 bg-pink-200 hover:bg-pink-600 duration-200 p-1 text-md rounded-lg uppercase font-bold"
                  onClick={() => {
                    handleLogout();
                    handleHamburger();
                  }}
                >
                  Logout
                </button>
              </li>
            ) : (
              <li>
                <Link to="/Login">
                  <button className="w-[auto] text-gray-500 hover:text-pink-600 duration-200 py-1 px-2 text-md rounded-lg uppercase font-bold">
                    Login
                  </button>
                </Link>
              </li>
            )}
          </motion.ul>
        )}
      </AnimatePresence>
    </>
  );
};

// Navbar Menu Items Current / Active position using Green underline
function CustomLink({ to, children }) {
  const location = useLocation();

  return (
    <Link to={to}>
      <div
        className={
          location.pathname === to ? "underline-red active" : "underline-red"
        }
      >
        {children}
      </div>
    </Link>
  );
}

export default NavBar;
