/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { firestore } from "../firebase/firebase-config";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";

const useFirestore = (collectionName) => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    // Collection Reference
    const collectionRef = collection(firestore, collectionName);
    // Query on collectionRef to Order in descending order
    const collectionOrderQuery = query(
      collectionRef,
      orderBy("timestamp", "desc")
    );
    // onSnapshot gives real time data as it runs once on load and listens to any other changes in real time thereafter
    onSnapshot(collectionOrderQuery, (snapshot) => {
      var documents = [];
      snapshot.docs.forEach((doc) => {
        documents.push({ ...doc.data(), id: doc.id });
      });
      setDocs(documents);
    });
  }, [collectionName]);

  return { docs };
};

export default useFirestore;
