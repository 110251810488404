import React from "react";
import { useEffect } from "react";
import useStorage from "../customHooks/useStorage";
import { motion } from "framer-motion";

const ProgressBar = (props) => {
  const { category, setCategory, photoFile, setPhotoFile } = props;

  const { url, progress } = useStorage(category, photoFile);

  useEffect(() => {
    if (url) {
      setCategory("");
      setPhotoFile("");
    }
  }, [url, setCategory, setPhotoFile]);

  return (
    <div>
      {/* Container Top Accent */}
      <motion.div
        className="h-3 bg-[#ec489a] rounded-xl"
        initial={{ width: 0 }}
        animate={{ width: progress + "%" }}
      ></motion.div>
    </div>
  );
};

export default ProgressBar;
