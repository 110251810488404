import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

// Make sure to switch between DEVELOPMENT and PRODUCTION variables as needed
// Currently using PROD as in PRODUCTION
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_PROD,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PROD,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_PROD,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PROD,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_PROD,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_PROD,
};

// Initialising Firestore App
const app = initializeApp(firebaseConfig);

// Initialising Firestore Services
const auth = getAuth(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { auth, storage, firestore };
