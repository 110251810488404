import React from "react";
import { Link } from "react-router-dom";
import LogoMP4 from "../assets/Logo.mp4";
import LogoMOV from "../assets/Logo.mov";
import LogoWEBM from "../assets/Logo.webm";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";

const Footer = () => {
  return (
    <div className="w-full h-[auto] z-5 pt-3 pb-5 px-5 text-white text-[0.6rem] lg:text-sm flex flex-col items-center justify-center">
      {/* Socials Icons */}
      <div>
        <ul className="w-full flex flex-wrap justify-center gap-2 lg:gap-10 duration-200 ">
          {/* Instagram */}
          <li>
            <a
              href="https://www.instagram.com/andrearizzomt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram
                size={35}
                className=" text-gray-400 hover:text-pink-500 duration-200 scale-75 lg:scale-100"
              />
            </a>
          </li>
          {/* Facebook */}
          <li>
            <a
              href="https://www.facebook.com/arizzomt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF
                size={35}
                className="text-gray-400 hover:text-pink-500 duration-200 scale-75 lg:scale-100"
              />
            </a>
          </li>
          {/* Contact */}
          <li>
            <Link to="/Contact">
              <HiOutlineMail
                size={35}
                className="text-gray-400 hover:text-pink-500 duration-200 scale-75 lg:scale-100"
              />
            </Link>
          </li>
        </ul>
      </div>
      {/* Copyright */}
      <div className="relative w-full mt-3 flex flex-col gap-5 sm:gap-2 text-gray-500 items-center justify-center text-center uppercase font-medium">
        {/* Images and Content Copyright */}
        <div>
          <p>
            <em>
              All content and images are subject to Copyright. Please{" "}
              <span>
                {" "}
                <Link
                  to="/Contact"
                  className="  text-indigo-700 hover:underline font-bold duration-200 tracking-wider"
                >
                  contact
                </Link>
              </span>{" "}
              for editorial or commercial use.
            </em>
          </p>
        </div>
        {/* Andrea Rizzo Photo and andrearizzo.com.mt Copyright*/}
        <div className="flex items-center gap-1 flex-wrap justify-center">
          <Link to="/">
            <span className="text-pink-700 font-bold tracking-wider">
              <em>Andrea Rizzo Photo</em>
            </span>
          </Link>
          <span className="font-bold">
            <em>Copyright © 2022</em>
          </span>
          <span className="text-pink-700 font-bold cursor-pointer tracking-wider">
            <a
              href="https://www.andrearizzo.com.mt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <em>Andrea Rizzo</em>
            </a>
          </span>
          <div>
            <a
              href="https://www.andrearizzo.com.mt/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <video
                autoPlay
                loop
                muted
                playsInline
                className="w-6 h-6 rounded-xl"
              >
                <source src={LogoMP4} type="video/mp4"></source>
                <source src={LogoWEBM} type="video/webm"></source>
                <source src={LogoMOV} type="video/mov"></source>
              </video>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
