import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FcHome } from "react-icons/fc";
import { motion } from "framer-motion";

const Login = (props) => {
  const {
    email,
    setEmail,
    password,
    setPassword,
    handleLogin,
    emailError,
    passwordError,
    clearErrors,
    clearInputs,
  } = props;

  // Useeffect to monitor Enter keypress instead of Button Click for User Login
  useEffect(() => {
    const keyDownHandler = (event) => {
      // console.log("User pressed: ", event.key);
      if (event.key === "Enter") {
        event.preventDefault();
        handleLogin();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [handleLogin]);

  return (
    <motion.div
      className="w-screen min-h-screen px-5 py-10 text-gray-500 flex flex-row items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="text-center w-96">
        <p className="text-2xl font-medium uppercase pb-5">Login</p>
        {/* Container Top Accent */}
        <div className="h-3 bg-pink-500 rounded-t-xl animate-pulse"></div>
        {/* Login Container */}
        <div className="flex flex-col bg-gray-900 p-5 text-left  rounded-b-xl">
          {/* Email */}
          <label className="text-lg font-normal uppercase">Email</label>
          <input
            className="bg-gray-800 w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-2 focus:ring-pink-500 rounded-lg text-md text-gray-500"
            type="email"
            autoFocus
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <p className="mt-1 text-red-600 text-sm font-medium uppercase tracking-widest">
            {emailError}&nbsp;
          </p>
          {/* Password */}
          <label className="text-lg font-normal uppercase">Password</label>
          <input
            className="bg-gray-800 w-full h-5 px-3 py-5 mt-2 hover:outline-none focus:outline-none focus:ring-2 focus:ring-pink-500 rounded-lg text-md text-gray-500"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <p className="my-1 text-red-600 text-sm font-medium uppercase tracking-widest">
            {passwordError}&nbsp;
          </p>
          {/* Login Button */}
          <div className="mt-2 flex flex-col items-center gap-2">
            <button
              className="w-[auto] text-gray-800 bg-pink-200 hover:bg-pink-600 duration-200 py-2 px-6 rounded-lg text-xl font-bold uppercase pulseLoginButton"
              onClick={handleLogin}
            >
              Login
            </button>
            {/* Forgot Link - Currently Disabled no cursor and pointer events */}
            <div className="mt-5 flex flex-col gap-2 items-center justify-center text-center">
              <Link
                to=""
                className="mt-1 text-sm hover:underline uppercase cursor-not-allowed"
              >
                <em>Forgot password ?</em>
              </Link>
              {/* No Account */}
              <p className="text-sm uppercase">
                <em>
                  Don't have an account ?{" "}
                  <span
                    className=" text-indigo-700"
                    onClick={() => {
                      clearErrors();
                      clearInputs();
                    }}
                  >
                    {/* No Account Button */}
                    <Link
                      to="/Register"
                      className="text-sm hover:underline font-bold uppercase tracking-wider"
                    >
                      {" "}
                      Register
                    </Link>
                  </span>
                </em>
              </p>
              {/* Home Button */}
              <Link to="/">
                <FcHome
                  size={35}
                  onClick={() => {
                    clearErrors();
                    clearInputs();
                  }}
                  className="mt-5 animate-bounce"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Login;
